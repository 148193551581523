class FetchElement extends HTMLElement {
  static {
    customElements.define("hl-fetch", this)
  }

  connectedCallback() {
    if (this.type === "connected") {
      this.fetch()
    } else if (this.type === "form") {
      this.submitter = null
      this.addEventListener("submit", this)
    }
  }

  get fetching() {
    return this.hasAttribute("fetching")
  }

  get type() {
    return this.getAttribute("type")
  }

  set type(newType) {
    this.setAttribute("type", newType)
  }

  get associatedForm() {
    return this.querySelector(":scope > form")
  }

  handleEvent(event) {
    if (event.type === "submit" && event.target === this.associatedForm) {
      if (event.submitter) {
        this.submitter = event.submitter
        this.submitter.ariaDisabled = true
      }
      event.preventDefault()
      this.fetch()
    }
  }

  async fetch() {
    let remoteElement, remoteForm, fetchedDocument

    if (this.fetching) return;

    const url = this.type === "form" ? this.associatedForm.action : this.getAttribute("src")
    if (!url) return;

    this.setAttribute("fetching", "")
    const finalize = () => {
      if (this.submitter) {
        this.submitter.ariaDisabled = false
        this.submitter = null
      }
      this.removeAttribute("fetching")
    }

    const options = {}
    if (this.type === "form") {
      options.body = new FormData(this.associatedForm)
      options.method = this.associatedForm.method
    }

    try {
      const results = await fetch(url, {...options})
      if (results.status < 500) {
        if (results.redirected && !this.hasAttribute("follow-redirect")) {
          if (this.type === "form") {
            location.href = results.url
            return
          } else {
            finalize()
            return
          }
        }

        const html = await results.text()

        fetchedDocument = new DOMParser().parseFromString(html, "text/html")

        if (this.hasAttribute("remote-query")) {
          remoteElement = fetchedDocument.body.querySelector(this.getAttribute("remote-query"))
        }
        if (this.type === "form" && this.associatedForm.id) {
          remoteForm = fetchedDocument.body.querySelector(`#${this.associatedForm.id}`)
        }
      }
      // TODO: maybe dispatch an event when there's an error?
    } catch(err) {
      console.warn(err)
    }

    if (!remoteElement && !remoteForm) {
      finalize()
      return // TODO: fire another event?
    }

    // TODO: hydrate shadow roots
    if (remoteElement)
      remoteElement.querySelectorAll("template[shadowrootmode]").forEach(item => item.remove())

    if (!this.dispatchEvent(new CustomEvent("hl-fetch:beforeFetch", { bubbles: true,
      cancelable: true, detail: {
        fetchedDocument,
        remoteElement,
        remoteForm
      }
    }))) {
      finalize()
      return;
    }

    if (this.hasAttribute("this-query")) {
      this.querySelector(this.getAttribute("this-query")).replaceChildren(...remoteElement.childNodes)
    } else if (this.hasAttribute("global-query")) {
      document.querySelector(this.getAttribute("global-query")).replaceChildren(...remoteElement.childNodes)
    }

    if (remoteForm) {
      this.associatedForm.replaceChildren(...remoteForm.childNodes)
    }

    finalize()

    this.dispatchEvent(new CustomEvent("hl-fetch:afterFetch", { bubbles: true,
      cancelable: true, detail: {
        fetchedDocument,
        remoteElement,
        remoteForm
      }
    }))
  }
}
